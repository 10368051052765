export const stockGr = [
  {
    name: "VN30",
    data: [
      "ACB",
      "BCM",
      "BID",
      "BVH",
      "CTG",
      "FPT",
      "GAS",
      "GVR",
      "HDB",
      "HPG",
      "MBB",
      "MSN",
      "MWG",
      "PLX",
      "POW",
      "SAB",
      "SHB",
      "SSB",
      "SSI",
      "STB",
      "TCB",
      "TPB",
      "VCB",
      "VHM",
      "VIB",
      "VIC",
      "VJC",
      "VNM",
      "VPB",
      "VRE",
    ],
  },
  {
    name: "VN100",
    data: [
      "AAA",
      "ACB",
      "ANV",
      "ASM",
      "BCG",
      "BCM",
      "BID",
      "BMP",
      "BSI",
      "BVH",
      "BWE",
      "CII",
      "CMG",
      "CRE",
      "CTD",
      "CTG",
      "CTR",
      "DBC",
      "DCM",
      "DGC",
      "DGW",
      "DIG",
      "DPM",
      "DXG",
      "DXS",
      "EIB",
      "EVF",
      "FPT",
      "FRT",
      "FTS",
      "GAS",
      "GEX",
      "GMD",
      "GVR",
      "HAG",
      "HCM",
      "HDB",
      "HDC",
      "HDG",
      "HHV",
      "HPG",
      "HSG",
      "HT1",
      "IMP",
      "KBC",
      "KDC",
      "KDH",
      "KOS",
      "LPB",
      "MBB",
      "MSB",
      "MSN",
      "MWG",
      "NKG",
      "NLG",
      "NT2",
      "NVL",
      "OCB",
      "PAN",
      "PC1",
      "PDR",
      "PHR",
      "PLX",
      "PNJ",
      "POW",
      "PPC",
      "PTB",
      "PVD",
      "PVT",
      "REE",
      "SAB",
      "SBT",
      "SCS",
      "SHB",
      "SIP",
      "SJS",
      "SSB",
      "SSI",
      "STB",
      "SZC",
      "TCB",
      "TCH",
      "TLG",
      "TPB",
      "VCB",
      "VCG",
      "VCI",
      "VGC",
      "VHC",
      "VHM",
      "VIB",
      "VIC",
      "VIX",
      "VJC",
      "VND",
      "VNM",
      "VPB",
      "VPI",
      "VRE",
      "VSH",
    ],
  },
  {
    name: "VNCOND",
    data: [
      "ADS",
      "AST",
      "CSM",
      "CTF",
      "DAH",
      "DRC",
      "DSN",
      "EVE",
      "FRT",
      "GDT",
      "GIL",
      "GTA",
      "HAX",
      "HTG",
      "HTL",
      "KMR",
      "MSH",
      "MWG",
      "NHT",
      "PAC",
      "PNJ",
      "PTB",
      "SAV",
      "SBV",
      "SRC",
    ],
  },
  {
    name: "VNCONS",
    data: [
      "AAM",
      "ABT",
      "ACL",
      "ANV",
      "BAF",
      "CMX",
      "DBC",
      "FMC",
      "HAG",
      "HSL",
      "IDI",
      "KDC",
      "LAF",
      "LIX",
      "LSS",
      "MSN",
      "NAF",
      "NSC",
      "PAN",
      "SAB",
      "SBT",
      "SMB",
      "VHC",
      "VNM",
    ],
  },
  {
    name: "VNDIAMOND",
    data: [
      "ACB",
      "BMP",
      "CTG",
      "FPT",
      "GMD",
      "HDB",
      "KDH",
      "MBB",
      "MSB",
      "MWG",
      "NLG",
      "OCB",
      "PNJ",
      "REE",
      "TCB",
      "TPB",
      "VIB",
      "VPB",
      "VRE",
    ],
  },
  {
    name: "VNENE",
    data: ["CNG", "GSP", "PGC", "PLX", "PVD", "PVP", "PVT", "TDG"],
  },
  {
    name: "VNFIN",
    data: [
      "ACB",
      "AGR",
      "APG",
      "BIC",
      "BID",
      "BMI",
      "BSI",
      "BVH",
      "CTG",
      "CTS",
      "EIB",
      "EVF",
      "FIT",
      "FTS",
      "HCM",
      "HDB",
      "LPB",
      "MBB",
      "MIG",
      "MSB",
      "OCB",
      "OGC",
      "ORS",
      "SHB",
      "SSB",
      "SSI",
      "STB",
      "TCB",
      "TPB",
      "TVS",
      "VCB",
      "VCI",
      "VDS",
      "VIB",
      "VIX",
      "VND",
      "VPB",
    ],
  },
  {
    name: "VNFINLEAD",
    data: [
      "ACB",
      "BID",
      "CTG",
      "EIB",
      "HCM",
      "HDB",
      "LPB",
      "MBB",
      "MSB",
      "NAB",
      "NLG",
      "OCB",
      "SHB",
      "SSB",
      "SSI",
      "STB",
      "TCB",
      "TPB",
      "VCB",
      "VCI",
      "VIB",
      "VIX",
      "VND",
      "VPB",
    ],
  },
  {
    name: "VNFINSELECT",
    data: [
      "ACB",
      "AGR",
      "APG",
      "BIC",
      "BID",
      "BMI",
      "BSI",
      "BVH",
      "CTG",
      "CTS",
      "EIB",
      "EVF",
      "FIT",
      "FTS",
      "HCM",
      "HDB",
      "LPB",
      "MBB",
      "MIG",
      "MSB",
      "OCB",
      "OGC",
      "ORS",
      "SHB",
      "SSB",
      "SSI",
      "STB",
      "TCB",
      "TPB",
      "TVS",
      "VCB",
      "VCI",
      "VDS",
      "VIB",
      "VIX",
      "VND",
      "VPB",
    ],
  },
  {
    name: "VNHEAL",
    data: ["DBD", "DBT", "DCL", "DMC", "IMP", "JVC", "TNH"],
  },
  {
    name: "VNIND",
    data: [
      "ABR",
      "BCE",
      "BCG",
      "BKG",
      "BMP",
      "BRC",
      "C32",
      "C47",
      "CDC",
      "CII",
      "CLL",
      "CTD",
      "CTR",
      "DC4",
      "DIG",
      "DPG",
      "DQC",
      "DVP",
      "FCN",
      "GEX",
      "GMD",
      "HAH",
      "HCD",
      "HDG",
      "HHS",
      "HHV",
      "HID",
      "HTI",
      "HTV",
      "HUB",
      "HVH",
      "IJC",
      "ITD",
      "LCG",
      "MHC",
      "NCT",
      "NHA",
      "NO1",
      "PC1",
      "PET",
      "PHC",
      "PIT",
      "PTC",
      "RAL",
      "REE",
      "SAM",
      "SBG",
      "SCS",
      "SFI",
      "SGN",
      "SHA",
      "SHI",
      "SKG",
      "ST8",
      "SZC",
      "TCD",
      "TCH",
      "TCL",
      "TCO",
      "TIP",
      "TLG",
      "TNI",
      "TV2",
      "TYA",
      "VCG",
      "VGC",
      "VIP",
      "VJC",
      "VNL",
      "VNS",
      "VOS",
      "VPG",
      "VRC",
      "VSC",
      "VTO",
    ],
  },
  {
    name: "VNIT",
    data: ["CMG", "DGW", "ELC", "FPT", "ICT"],
  },
  {
    name: "VNMAT",
    data: [
      "AAA",
      "ACC",
      "APH",
      "BFC",
      "BMC",
      "CRC",
      "CSV",
      "CTI",
      "DCM",
      "DGC",
      "DHA",
      "DHC",
      "DHM",
      "DPM",
      "DPR",
      "DXV",
      "FCM",
      "GMH",
      "GVR",
      "HAP",
      "HHP",
      "HII",
      "HMC",
      "HPG",
      "HSG",
      "HT1",
      "HVX",
      "KSB",
      "LBM",
      "MCP",
      "NHH",
      "NKG",
      "NNC",
      "PHR",
      "PLP",
      "SFG",
      "TDP",
      "THG",
      "TLD",
      "TLH",
      "TNT",
      "TRC",
      "VCA",
      "VFG",
      "VID",
      "YBM",
    ],
  },
  {
    name: "VNMID",
    data: [
      "AAA",
      "ANV",
      "ASM",
      "BCG",
      "BMP",
      "BSI",
      "BWE",
      "CII",
      "CMG",
      "CRE",
      "CTD",
      "CTR",
      "DBC",
      "DCM",
      "DGC",
      "DGW",
      "DIG",
      "DPM",
      "DXG",
      "DXS",
      "EIB",
      "EVF",
      "FRT",
      "FTS",
      "GEX",
      "GMD",
      "HAG",
      "HCM",
      "HDC",
      "HDG",
      "HHV",
      "HSG",
      "HT1",
      "IMP",
      "KBC",
      "KDC",
      "KDH",
      "KOS",
      "LPB",
      "MSB",
      "NKG",
      "NLG",
      "NT2",
      "NVL",
      "OCB",
      "PAN",
      "PC1",
      "PDR",
      "PHR",
      "PNJ",
      "PPC",
      "PTB",
      "PVD",
      "PVT",
      "REE",
      "SBT",
      "SCS",
      "SIP",
      "SJS",
      "SZC",
      "TCH",
      "TLG",
      "VCG",
      "VCI",
      "VGC",
      "VHC",
      "VIX",
      "VND",
      "VPI",
      "VSH",
    ],
  },
  {
    name: "VNREAL",
    data: [
      "AGG",
      "ASM",
      "BCM",
      "CCL",
      "CRE",
      "D2D",
      "DTA",
      "DXG",
      "DXS",
      "FIR",
      "HAR",
      "HDC",
      "HPX",
      "HQC",
      "HTN",
      "ITC",
      "KBC",
      "KDH",
      "KHG",
      "KOS",
      "LHG",
      "NBB",
      "NLG",
      "NTL",
      "NVL",
      "PDR",
      "PTL",
      "QCG",
      "SCR",
      "SGR",
      "SIP",
      "SJS",
      "SZL",
      "TDC",
      "TDH",
      "TEG",
      "VHM",
      "VIC",
      "VPH",
      "VPI",
      "VRE",
    ],
  },
  {
    name: "VNSI",
    data: [
      "BCM",
      "BID",
      "BMP",
      "BVH",
      "CTD",
      "CTG",
      "DCM",
      "FPT",
      "GMD",
      "HDB",
      "IMP",
      "MBB",
      "MWG",
      "PAN",
      "PNJ",
      "SBT",
      "VCB",
      "VIC",
      "VNM",
      "VPB",
    ],
  },
  {
    name: "VNSML",
    data: [
      "AAM",
      "ABR",
      "ABT",
      "ACC",
      "ACL",
      "ADS",
      "AGG",
      "AGR",
      "APG",
      "APH",
      "AST",
      "BAF",
      "BCE",
      "BFC",
      "BIC",
      "BKG",
      "BMC",
      "BMI",
      "BRC",
      "BTP",
      "C32",
      "C47",
      "CCL",
      "CDC",
      "CLL",
      "CMX",
      "CNG",
      "CRC",
      "CSM",
      "CSV",
      "CTF",
      "CTI",
      "CTS",
      "D2D",
      "DAH",
      "DBD",
      "DBT",
      "DC4",
      "DCL",
      "DHA",
      "DHC",
      "DHM",
      "DMC",
      "DPG",
      "DPR",
      "DQC",
      "DRC",
      "DSN",
      "DTA",
      "DVP",
      "DXV",
      "ELC",
      "EVE",
      "FCM",
      "FCN",
      "FIR",
      "FIT",
      "FMC",
      "GDT",
      "GIL",
      "GMH",
      "GSP",
      "GTA",
      "HAH",
      "HAP",
      "HAR",
      "HAX",
      "HCD",
      "HHP",
      "HHS",
      "HID",
      "HII",
      "HMC",
      "HPX",
      "HQC",
      "HSL",
      "HTG",
      "HTI",
      "HTL",
      "HTN",
      "HTV",
      "HUB",
      "HVH",
      "HVX",
      "ICT",
      "IDI",
      "IJC",
      "ITC",
      "ITD",
      "JVC",
      "KHG",
      "KHP",
      "KMR",
      "KSB",
      "LAF",
      "LBM",
      "LCG",
      "LHG",
      "LIX",
      "LSS",
      "MCP",
      "MHC",
      "MIG",
      "MSH",
      "NAF",
      "NBB",
      "NCT",
      "NHA",
      "NHH",
      "NHT",
      "NNC",
      "NO1",
      "NSC",
      "NTL",
      "OGC",
      "ORS",
      "PAC",
      "PET",
      "PGC",
      "PHC",
      "PIT",
      "PLP",
      "PTC",
      "PTL",
      "PVP",
      "QCG",
      "RAL",
      "SAM",
      "SAV",
      "SBG",
      "SBV",
      "SCR",
      "SFG",
      "SFI",
      "SGN",
      "SGR",
      "SGT",
      "SHA",
      "SHI",
      "SJD",
      "SKG",
      "SMB",
      "SRC",
      "ST8",
      "STK",
      "SVT",
      "SZL",
      "TCD",
      "TCL",
      "TCM",
      "TCO",
      "TCT",
      "TDC",
      "TDG",
      "TDH",
      "TDP",
      "TEG",
      "THG",
      "TIP",
      "TLD",
      "TLH",
      "TMT",
      "TNH",
      "TNI",
      "TNT",
      "TRC",
      "TTA",
      "TTF",
      "TV2",
      "TVS",
      "TVT",
      "TYA",
      "UIC",
      "VCA",
      "VDS",
      "VFG",
      "VID",
      "VIP",
      "VNG",
      "VNL",
      "VNS",
      "VOS",
      "VPG",
      "VPH",
      "VRC",
      "VSC",
      "VTB",
      "VTO",
      "YBM",
      "YEG",
    ],
  },
  {
    name: "VNUTI",
    data: [
      "BTP",
      "BWE",
      "GAS",
      "KHP",
      "NT2",
      "POW",
      "PPC",
      "SJD",
      "TTA",
      "UIC",
      "VSH",
    ],
  },
  {
    name: "VNX50",
    data: [
      "ACB",
      "CTG",
      "DCM",
      "DGC",
      "DIG",
      "DPM",
      "EIB",
      "FPT",
      "FRT",
      "GEX",
      "GMD",
      "HCM",
      "HDB",
      "HPG",
      "HSG",
      "HUT",
      "IDC",
      "KBC",
      "KDC",
      "KDH",
      "LPB",
      "MBB",
      "MSB",
      "MSN",
      "MWG",
      "NLG",
      "PDR",
      "PNJ",
      "POW",
      "PVD",
      "PVS",
      "SHB",
      "SHS",
      "SSB",
      "SSI",
      "STB",
      "TCB",
      "TPB",
      "VCB",
      "VCG",
      "VCI",
      "VHM",
      "VIB",
      "VIC",
      "VJC",
      "VND",
      "VNM",
      "VPB",
      "VPI",
      "VRE",
    ],
  },
];
